import React, { useRef, useState, useEffect, Suspense } from 'react'
import { Stage, Layer, Group, TextPath, Text, Rect, Circle, Image, Transformer } from "react-konva";
import CONFIG from '../config'
import setStateCallback from './setStateCallback'

class TransformerComponent extends React.Component {

    componentDidMount() {
        this.checkNode();
    }

    componentDidUpdate() {
        this.checkNode();
    }

    checkNode() {
        const stage = this.transformer.getStage();
        const { selectedShapeName } = this.props;

        var selectedNode = stage.findOne("." + selectedShapeName);

        if (selectedNode === this.transformer.node()) {
            return;
        }

        if (selectedNode) {
            const type = selectedNode.getType();
            if (type != "Group") {
                selectedNode = selectedNode.findAncestor("Group");
            }

            this.transformer.attachTo(selectedNode);
        } else {
            this.transformer.detach();
        }

        this.transformer.getLayer().batchDraw();
    }

    render() {
        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                transformstart={this.onTransformStart}
                transform={this.onTransform}
                transformend={this.onTransformEnd}
            />
        );
    }
}

function TextureBuilder(props) {
    const [baseColor, updateBaseColor] = useState("#ffffff");
    const [uploadedImage, updateUploadedImage] = useState([null, null, null]);
    const [selectedShapeName, updateSelected] = useState("")
    // const [current, updateCurrent] = useStateWithCallback(-1, () => {});
    const [current, updateCurrent] = useState(props.selected);
    const [lastState, updateLastState] = useState([{}, {}, {}])

    const stageRef = useRef(null);
    const inputRef = useRef(null);

    // useEffect(() => {
    //     props.handleUpdateColor(current, getImage);
    // }, [current])

    // useEffect(() => {
    //     [...Array(CONFIG.portionsAlias.length)].map((e, i) => {
    //         updateCurrent(i);
    //     });
    // }, [baseColor])
    useEffect(() => {
        setTimeout(() => {
            props.setTexture(current, getImage());
        }, 500);
    }, [])

    useEffect(async () => {

        Promise.resolve()
            .then(() => {
                setTimeout(() => {
                    props.setTexture(current, getImage());
                }, 75 * (current + 1));
            })

    }, [baseColor, uploadedImage])

    useEffect(() => {
        updateBaseColor(props.baseColor);
    }, [props.baseColor])

    useEffect(() => {
        updateUploadedImage(props.uploadedImage);
    }, [props.uploadedImage])

    useEffect(() => {
        updateLastState(props.lastState);
    }, [props.lastState])

    useEffect(() => {
        updateCurrent(CONFIG.portionsAlias.indexOf(props.selected), () => { });
    }, [props.selected])

    function ColoredRect(props) {
        return (
            <Rect
                width={props.width}
                height={props.height}
                fill={props.color}
            />
        );
    }

    function TextureImage(props) {
        const [image, updateImage] = useState(null)

        useEffect(() => {
            const image = new window.Image();
            image.src = props.img;
            image.crossOrigin = 'Anonymous';
            image.onload = () => {
                updateImage(image);
            };
        }, [])

        return <Image {...props} image={image} />;
    }

    const uploadImage = (event) => {

        if (current != -1) {

            var res = {};
            var blob = URL.createObjectURL(event.target.files[0]);
            var img = document.createElement('img');

            img.src = blob;
            img.onload = function () {
                var scaleWidth = CONFIG.canvasWidth / img.width;
                var scaleHeight = CONFIG.canvasHeight / img.height;
                var minScale = Math.min(scaleWidth, scaleHeight) - CONFIG.imageScaleFactor;

                res.image = blob;
                res.width = img.width * minScale;
                res.height = img.height * minScale;
                res.x = (CONFIG.canvasWidth - res.width) / 2;
                res.y = (CONFIG.canvasHeight - res.height) / 2;

                props.handleImageUpload(current, res);
            }

            props.handleUpdateLastState(current, {}, getImage());
        }

        // if (event.target.files.length > 0 &&
        //     event.target.files[0].type.slice(0, CONFIG.imageType.length) == CONFIG.imageType) {

        //     var name = ""
        //     Promise.resolve()
        //         .then(() => updateSelected(""))
        //         .then(() => {
        //             if (uploaded.filter(e => e.name == event.target.files[0].name).length == 0) {
        //                 name = event.target.files[0].name;
        //             }
        //             else {
        //                 var nr = uploaded.reduce((max, elem) => {
        //                     if (elem.name.startsWith(event.target.files[0].name) && elem.name.length > event.target.files[0].name.length) {
        //                         var splitedName = elem.name.split(" ");
        //                         var val = parseInt(splitedName[splitedName.length - 1].slice(1, -1))

        //                         if (val == max) {
        //                             max++;
        //                         }
        //                     }
        //                     return max;
        //                 }, 1);

        //                 name = event.target.files[0].name + ` (${nr})`
        //             }
        //         })
        //         .then(() => updateUploaded([...uploaded, {
        //             data: URL.createObjectURL(event.target.files[0]),
        //             name: name,
        //             htmlName: makeid(10)
        //         }]));
        // }
    }

    const handleStageMouseDown = (e) => {
        if (e.target === e.target.getStage() || e.target.name() == "groupbaseimg" || uploadedImage[current] === null) {
            updateSelected("")
            return;
        }

        const clickedOnTransformer = e.target.getParent().className === "Transformer";

        if (clickedOnTransformer) {
            return;
        }

        const name = e.target.name();

        if (name) {
            updateSelected(name)
        }
        else {
            updateSelected("")
        }
    };

    const handleUpdateLastState = (attrs) => {
        // props.handleUpdateLastState(current, attrs, getImage());

        var selected;
        Promise.resolve()
            .then(() => {
                selected = selectedShapeName;
                // updateSelected("");
            })
            .then(() => {
                setTimeout(() => {
                    console.log("stageRef.current")
                    console.log(stageRef.current)
                    props.handleUpdateLastState(current, attrs, getImage());
                    updateSelected(selected);
                }, 20);
            })
    }

    const getImage = () => {
        if (stageRef.current) {
            stageRef.current.children[1].children[1].visible(false);
            const uri = stageRef.current.toDataURL();
            stageRef.current.children[1].children[1].visible(true);
          
            return uri;
        }
    };

    return (
        <div style={{ "display": props.display ? "" : "none" }}>
            {/* <div>a: {JSON.stringify(lastState)}</div> */}
            {/* <div>
                <label for="select">Selecteaza portiunea:</label>
                <select
                    id="select"
                    onChange={(e) => updateCurrent(e.target.value)}
                >
                    <option value={0}>Unu</option>
                    <option value={1}>Doi</option>
                    <option value={2}>Trei</option>
                </select>
            </div> */}
            {/* <div>{JSON.stringify(current)} {JSON.stringify(props.display)} {props.display ? "" : "hidden"}</div> */}
            {
                current != -1 &&
                <Stage
                    ref={stageRef}
                    width={CONFIG.canvasWidth}
                    height={CONFIG.canvasHeight}
                    onMouseDown={handleStageMouseDown}
                >
                    <Layer key="baseColor">
                        <ColoredRect
                            color={baseColor}
                            width={CONFIG.canvasWidth}
                            height={CONFIG.canvasHeight}
                        />
                    </Layer>
                    {
                        uploadedImage &&

                        <Layer key={"imgcontainer" + current}>
                            <Group
                                {...(lastState[current])}
                                key={"gorupimg" + current}
                                name={"gorupimg" + current}
                                draggable
                                onDragEnd={(e) => handleUpdateLastState(e.target.attrs)}
                                onTransformEnd={(e) => handleUpdateLastState(e.target.attrs)}
                            >
                                <TextureImage
                                    key={"img" + current}
                                    name={"img" + current}
                                    img={uploadedImage[current]?.image}
                                    width={uploadedImage[current]?.width}
                                    height={uploadedImage[current]?.height}
                                    x={uploadedImage[current]?.x}
                                    y={uploadedImage[current]?.y}
                                />
                            </Group>
                            <TransformerComponent
                                key={"transformer" + current}
                                selectedShapeName={selectedShapeName}
                            />
                        </Layer>
                    }
                    <Layer key="ceva" listening={false}>
                        {/* <TextureImage
                            width={CONFIG.canvasWidth}
                            height={CONFIG.canvasHeight}
                            img={CONFIG.margins[current]}
                        /> */}
                    </Layer>
                </Stage>
            }
            <input ref={inputRef} hidden id="upload-file" type="file" onChange={uploadImage}></input>
            <button onClick={() => inputRef.current.click()}>Upload</button>
            <button onClick={() => props.handleImageUpload(current, null)}>Remove</button>
            {/* <button onClick={() => console.log(getImage())}>Test</button> */}
            {/* <button onClick={() => console.log(stageRef.current)}>Davai</button> */}
        </ div>
    )
}

export default TextureBuilder;