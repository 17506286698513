import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Label, Input, Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import TextureBuilderEntire from './TextureBuilderEntire.js'
import Exporter from './Exporter'
import QRCode from 'qrcode.react';
import CONFIG from '../config'
import shoeConfig from '../shoeConfig'
import { useParams } from "react-router";

function Accordion(props) {
    const [collapse, updateCollapse] = useState(0);
    const [qr, updateQR] = useState(null);
    const [scene, updateScene] = useState(null);
    const [perforated, updatePerforated] = useState(false);
    const [sole, updateSole] = useState("Default");
    const [details, updateDetails] = useState("");
    const [measurments, updateMeasurments] = useState({
        length: "",
        width: "",
        circumference: "",
        lengthErr: true,
        widthErr: true,
        circumferenceErr: true,
    });
    const { modelId } = useParams();

    useEffect(() => {
        updateScene(props.scene);
    }, [props.scene])

    const addToCart = () => {
        var colors = {};

        for (let k in props.colors) {
            colors[k + 'Color'] = props.colors[k];
        }

        var body = {
            modelId: modelId,
            perforated: perforated,
            sole: sole,
            details: details,
            length: measurments.length,
            width: measurments.width,
            circumference: measurments.circumference,
            ...colors,
            media: JSON.parse(localStorage.getItem('uploadedImages'))?.map(e => e.data) || []
        }

        console.log(body)
    }

    // const measurmentsRegex = new RegExp('^[1-9][1-9]?[1-9]?[.]?[1-9]*$');
    const measurmentsRegex = new RegExp('^[0-9]+$');

    const toggle = (e) => {
        let event = e.target.dataset.event;
        updateCollapse(collapse === Number(event) ? -1 : Number(event));
    }

    return (
        <>
            {/* <hr /> */}
            <Card className={"accordion-cell"} key={0}>
                <CardHeader className={"accordion-cell-header" + (collapse === 0 ? " opened-cell" : "") + ((measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) == false ? " solved" : "")} onClick={toggle} data-event={0}>Măsuri</CardHeader>
                <Collapse isOpen={collapse === 0}>
                    <CardBody id="measurement-zone">
                        <Col xs={12}>
                            <Row>
                                <Label for="lengthInput">Lungime (cm)</Label>
                                <Input
                                    className="input-box"
                                    name="length"
                                    id="lengthInput"
                                    placeholder="Lungime talpă"
                                    value={measurments.length}
                                    onChange={(e) => { e.persist(); updateMeasurments({ ...measurments, length: e.target.value, lengthErr: !measurmentsRegex.test(e.target.value) }); }}
                                />
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Label for="widthInput">Lățime (cm)</Label>
                                <Input
                                    className="input-box"
                                    name="width"
                                    id="widthInput"
                                    placeholder="Lățime talpă"
                                    value={measurments.width}
                                    onChange={(e) => { e.persist(); updateMeasurments({ ...measurments, width: e.target.value, widthErr: !measurmentsRegex.test(e.target.value) }); }}
                                />
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Label for="circumferenceInput">Circumferință (cm)</Label>
                                <Input
                                    className="input-box"
                                    name="circumference"
                                    id="circumferenceInput"
                                    placeholder="Circumferință picior"
                                    value={measurments.circumference}
                                    onChange={(e) => { e.persist(); updateMeasurments({ ...measurments, circumference: e.target.value, circumferenceErr: !measurmentsRegex.test(e.target.value) }); }}
                                />
                            </Row>
                        </Col>
                        {/* {measurments.lengthErr + " " + measurments.widthErr + " " + measurments.circumferenceErr} */}
                        {
                            (measurments.lengthErr || measurments.widthErr || measurments.circumferenceErr) &&
                            <Col xs={12}>

                                <Row className="error-message">
                                    Valorile introduse trebuie sa contina doar cifre.
                                </Row>
                            </Col>
                        }
                    </CardBody>
                </Collapse>
            </Card>
            <Card className={"accordion-cell" + ((measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) ? " disabled" : "")} key={1}>
                <CardHeader className={"accordion-cell-header" + (collapse === 1 ? " opened-cell" : "")} onClick={(measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) == false ? toggle : () => { }} data-event={1}>Culori</CardHeader>
                <Collapse isOpen={collapse === 1}>
                    <CardBody>
                        {
                            shoeConfig[modelId].zoneName.map((zone, i) => {
                                return (
                                    <Row>
                                        <Col sm={3} xs={12}>
                                            {shoeConfig[modelId].zoneAlias[zone]}
                                        </Col>
                                        <Col className="color-button-container" sm={9} xs={12}>
                                            {
                                                shoeConfig[modelId].zoneColor[zone].map((elem, i) => {
                                                    return (
                                                        <button
                                                            key={i}
                                                            className={elem == props.colors[zone] ? "color-button-selected" : "color-button"}
                                                            style={{ "background-color": elem }}
                                                            onClick={(e) => props.updateColorZone(elem, zone)}
                                                        />
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </CardBody>
                </Collapse>
            </Card>
            <Card className={"accordion-cell" + ((measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) ? " disabled" : "")} key={2}>

                <CardHeader className={"accordion-cell-header" + (collapse === 2 ? " opened-cell" : "")} onClick={(measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) == false ? toggle : () => { }} data-event={2}>Personalizare</CardHeader>
                <Collapse isOpen={collapse === 2}>
                    <CardBody>
                        {[...Array(props.nrZone).keys()].map((e, i) => {
                            return (
                                <Col key={i} xs={12}>
                                    <TextureBuilderEntire
                                        baseColor={props.baseColor[i]}
                                        setTexture={props.setTexture}
                                        display={collapse === 2}
                                        zone={shoeConfig[modelId].editableZone[i]}
                                        idx={i}
                                    />
                                </Col>
                            )
                        })}
                    </CardBody>
                </Collapse>
            </Card>
            <Card className={"accordion-cell" + ((measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) ? " disabled" : "")} key={3}>
                <CardHeader className={"accordion-cell-header" + (collapse === 3 ? " opened-cell" : "")} onClick={(measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) == false ? toggle : () => { }} data-event={3}>Caracteristici Pantofi</CardHeader>
                <Collapse isOpen={collapse === 3}>
                    <CardBody>
                        <Col xs={12}>

                            <Row>
                                <Label for="perforatedShoe">Pantofi perforați</Label>
                                <Input
                                    className="input-box"
                                    type="select"
                                    name="perforatedSelect"
                                    id="perforatedShoe"
                                    value={perforated}
                                    onChange={(e) => updatePerforated(e.target.value)}
                                >
                                    <option className="theme-option" value="Cu găuri">Cu găuri</option>
                                    <option className="theme-option" value="Fără găuri">Fără găuri</option>
                                </Input>
                            </Row>
                        </Col>
                        <Col xs={12}>

                            <Row>
                                <Label for="soleShoe">Talpă pantof</Label>
                                <Input
                                    className="input-box"
                                    type="select"
                                    name="soleSelect"
                                    id="soleShoe"
                                    value={sole}
                                    onChange={(e) => updateSole(e.target.value)}
                                >
                                    <option className="theme-option" value="Default">Default</option>
                                    <option className="theme-option" value="Opțiunea 1">Opțiunea 1</option>
                                    <option className="theme-option" value="Opțiunea 2">Opțiunea 2</option>
                                    <option className="theme-option" value="Opțiunea 3">Opțiunea 3</option>
                                    <option className="theme-option" value="Opțiunea 4">Opțiunea 4</option>
                                </Input>
                            </Row>
                        </Col>
                        <Col xs={12}>

                            <Row>
                                <Label for="deatils">Alte Detalii</Label>
                                <Input
                                    className="input-box"
                                    type="textarea"
                                    name="details"
                                    id="deatils"
                                    value={details}
                                    onChange={(e) => updateDetails(e.target.value)}
                                />
                            </Row>
                        </Col>
                    </CardBody>
                </Collapse>
            </Card>
            <Card className={"accordion-cell" + ((measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) ? " disabled" : "")} key={4}>
                <CardHeader className={"accordion-cell-header" + (collapse === 4 ? " opened-cell" : "")} onClick={(measurments.lengthErr || measurments.circumferenceErr || measurments.widthErr) == false ? toggle : () => { }} data-event={4}>AR</CardHeader>
                <Collapse isOpen={collapse === 4}>
                    <CardBody>
                        <Row className="centered-container">
                            <Button
                                className="theme-button"
                                onClick={() => Exporter(scene, updateQR)}
                            >
                                Vizualizare in AR
                            </Button>
                        </Row>
                        <Row className="centered-container">
                            {
                                qr != null &&
                                <QRCode value={qr} />
                            }
                        </Row>
                    </CardBody>
                </Collapse>
            </Card>
            <Card className="accordion-cell last-accordion-cell " key={5}>
                <CardHeader className={"accordion-cell-header" + (collapse === 5 ? " opened-cell" : "")} onClick={toggle} data-event={5}>Detalii Finale</CardHeader>
                <Collapse className="final-zone" isOpen={collapse === 5}>
                    <CardBody>
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Lungime (cm)
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {measurments.length}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Lățime (cm)
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {measurments.width}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Circumferință (cm)
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {measurments.circumference}
                                </Col>
                            </Row>
                        </Col>
                        {
                            shoeConfig[modelId].zoneName.map((zone, i) => {
                                return (
                                    <Col xs={12}>
                                        <Row>
                                            <Col sm={6} xs={12} className="no-padding">
                                                {shoeConfig[modelId].zoneAlias[zone]}
                                            </Col>
                                            <Col sm={6} xs={12} className="no-padding">
                                                <div
                                                    className="color-button"
                                                    style={{ "background-color": props.colors[zone], width: "30px", height: "30px" }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                )
                            })
                        }
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Pantofi perforați
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {perforated}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Talpă pantof
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {sole}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col sm={6} xs={12} className="no-padding">
                                    Alte Detalii
                                </Col>
                                <Col sm={6} xs={12} className="no-padding">
                                    {details}
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Button style={{ width: "100%" }} className="theme-button" onClick={addToCart}>
                                    Add to Cart
                                </Button>
                            </Row>
                        </Col>
                    </CardBody>
                </Collapse>
            </Card>
        </>
    );
}

export default Accordion;