import React, { useRef, useState, useEffect, Suspense } from 'react'
import CONFIG from '../config'
import { Container, Row, Col, Button, Label, Input, Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import TextureBuilderEntire from './TextureBuilderEntire.js'
import Exporter from './Exporter'
import QRCode from 'qrcode.react';
import './scene.css'

function Stepper(props) {
    const [toggleQuestion, setToggequestion] = useState(1);
    const [qr, updateQR] = useState(null);
    const [scene, updateScene] = useState(null);
    const [step, updateStep] = useState(0);
    const [perforated, updatePerforated] = useState(false);
    const [sole, updateSole] = useState("Default");
    const [details, updateDetails] = useState("");
    const [measurments, updateMeasurments] = useState({
        length: "",
        width: "",
        circumference: "",
        lengthErr: false,
        widthErr: false,
        circumferenceErr: false,
    });

    useEffect(() => {
        updateScene(props.scene);
    }, [props.scene])

    const measurmentsRegex = new RegExp('^[1-9][1-9]?[1-9]?[.]?[1-9]*$');

    return (
        <Container>
            <Row>
                <div className="button-container">
                    <div>
                        <button
                            className="theme-button step-button"
                            disabled={step == 0}
                            onClick={() => updateStep(step - 1)}
                        >
                            Înapoi
                    </button>
                    </div>
                    <div>
                        <button
                            className="theme-button step-button"
                            disabled={step == CONFIG.maxStep || (measurments.lengthErr || measurments.widthErr || measurments.circumferenceErr)}
                            onClick={() => updateStep(step + 1)}
                        >
                            Înainte
                    </button>
                    </div>
                </div>
            </Row>
            {
                step == 0 &&
                <>
                    <Row>
                        <Label for="lengthInput">Lungime (cm)</Label>
                        <Input
                            className="input-box"
                            name="length"
                            id="lengthInput"
                            placeholder="Lungime talpă"
                            value={measurments.length}
                            onChange={(e) => updateMeasurments({ ...measurments, length: e.target.value, lengthErr: !measurmentsRegex.test(e.target.value) })}
                        />
                    </Row>
                    <Row>
                        <Label for="widthInput">Lățime (cm)</Label>
                        <Input
                            className="input-box"
                            name="width"
                            id="widthInput"
                            placeholder="Lățime talpă"
                            value={measurments.width}
                            onChange={(e) => updateMeasurments({ ...measurments, width: e.target.value, widthErr: !measurmentsRegex.test(e.target.value) })}
                        />
                    </Row>
                    <Row>
                        <Label for="circumferenceInput">Circumferință (cm)</Label>
                        <Input
                            className="input-box"
                            name="circumference"
                            id="circumferenceInput"
                            placeholder="Circumferință picior"
                            value={measurments.circumference}
                            onChange={(e) => updateMeasurments({ ...measurments, circumference: e.target.value, circumferenceErr: !measurmentsRegex.test(e.target.value) })}
                        />
                    </Row>
                    {
                        (measurments.lengthErr || measurments.widthErr || measurments.circumferenceErr) &&
                        <Row>
                            Va rugăm să completați, se acceptă doar numere.
                        </Row>
                    }
                </>
            }
            {
                step == 1 &&
                <>
                    {
                        CONFIG.entireZoneName.map((zone, i) => {
                            return (
                                <Row>
                                    <Col sm={1} xs={12}>
                                        {CONFIG.entireZoneAlias[zone]}
                                    </Col>
                                    <Col className="color-button-container" sm={{ size: 10, offset: 1 }} xs={12}>
                                        {
                                            CONFIG.colors.map((elem, i) => {
                                                return (
                                                    <button
                                                        key={i}
                                                        className={elem == props.colors[zone] ? "color-button-selected" : "color-button"}
                                                        style={{ "background-color": elem }}
                                                        onClick={(e) => props.updateColorZone(elem, zone)}
                                                    />
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </>
            }
            {
                step == 3 &&
                <>
                    <Row>
                        <Label for="perforatedShoe">Pantofi perforați</Label>
                        <Input
                            className="input-box"
                            type="select"
                            name="perforatedSelect"
                            id="perforatedShoe"
                            value={perforated}
                            onChange={(e) => updatePerforated(e.target.value)}
                        >
                            <option className="theme-option" value="Cu găuri">Cu găuri</option>
                            <option className="theme-option" value="Fără găuri">Fără găuri</option>
                        </Input>
                    </Row>
                    <Row>
                        <Label for="soleShoe">Talpă pantof</Label>
                        <Input
                            className="input-box"
                            type="select"
                            name="soleSelect"
                            id="soleShoe"
                            value={sole}
                            onChange={(e) => updateSole(e.target.value)}
                        >
                            <option className="theme-option" value="Default">Default</option>
                            <option className="theme-option" value="Opțiunea 1">Opțiunea 1</option>
                            <option className="theme-option" value="Opțiunea 2">Opțiunea 2</option>
                            <option className="theme-option" value="Opțiunea 3">Opțiunea 3</option>
                            <option className="theme-option" value="Opțiunea 4">Opțiunea 4</option>
                        </Input>
                    </Row>
                    <Row>
                        <Label for="deatils">Alte Detalii</Label>
                        <Input
                            className="input-box"
                            type="textarea"
                            name="details"
                            id="deatils"
                            value={details}
                            onChange={(e) => updateDetails(e.target.value)}
                        />
                    </Row>
                </>
            }
            {
                step == 4 &&
                <>
                    <Row className="centered-container">
                        <Button
                            className="theme-button"
                            onClick={() => Exporter(scene, updateQR)}
                        >
                            Vizualizare in AR
                        </Button>
                    </Row>
                    <Row className="centered-container">
                        {
                            qr != null &&
                            <QRCode value={qr} />
                        }
                    </Row>
                </>
            }
            {
                step == 5 &&
                <>
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Lungime (cm)
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {measurments.length}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Lățime (cm)
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {measurments.width}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Circumferință (cm)
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {measurments.circumference}
                        </Col>
                    </Row>
                    {
                        CONFIG.entireZoneName.map((zone, i) => {
                            return (
                                <Row>
                                    <Col sm={4} xs={12} className="no-padding">
                                        {CONFIG.entireZoneAlias[zone]}
                                    </Col>
                                    <Col sm={8} xs={12} className="no-padding">
                                        <div
                                            className="color-button"
                                            style={{ "background-color": props.colors[zone] }}
                                        />
                                    </Col>
                                </Row>
                            )
                        })
                    }
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Pantofi perforați
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {perforated}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Talpă pantof
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {sole}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} xs={12} className="no-padding">
                            Alte Detalii
                        </Col>
                        <Col sm={8} xs={12} className="no-padding">
                            {details}
                        </Col>
                    </Row>
                    <Row>
                        <Button className="theme-button">
                            Confirmă
                        </Button>
                    </Row>
                </>
            }
            <TextureBuilderEntire
                baseColor={props.baseColor}
                setTexture={props.setTexture}
                display={step == CONFIG.shoeStep}
            />
        </Container>
    )
}

export default Stepper;