import './App.css';
import ShoeScene from './components/ShoeScene.js'
import ShoeSceneEntire from './components/ShoeSceneEntire.js'
import TextureBuilder from './components/TextureBuilder.js'
import Accordion from './components/Accordion.js'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route path={["/model/shoe", "/model/smallShoe"]}>
          <Route path="/model/:modelId" component={ShoeSceneEntire} />
        </Route>
        <Route path="*">
          <div className="model-select">
            <Link to="/model/shoe">Pantof Dama</Link>
            <Link to="/model/smallShoe">Sandaluta copii</Link>
          </div>
        </Route>
        {/* <Redirect to="/model/shoe" /> */}
      </Switch>
    </Router>
  );
}

export default App;
