import React, { useRef, useState, useEffect, Suspense } from 'react'
import { useGLTF, Html, OrbitControls, ContactShadows, Reflector, useTexture, Sky, Environment, Text, Billboard } from '@react-three/drei'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import { useParams } from "react-router";
import * as THREE from 'three'
import img0 from '../textures/rainbow.png'
import img1 from '../textures/fill.png'
import './style.css'
import Stepper from './Stepper'
import Accordion from './Accordion'
import { Container, Row, Col } from 'reactstrap';
import CONFIG from '../config'
import './scene.css'
import shoeConfig from '../shoeConfig'

import red from '../textures/red.png'
import yellow from '../textures/yellow.png'
import blue from '../textures/blue.png'
import white from '../textures/white.png'
import black from '../textures/black.png'
import roughness from '../textures/roughness_floor.jpeg';

export default function ShoeScene(props) {
  const [goBack, updateGoBack] = useState(false);
  const { modelId } = useParams();
  const [qr, updateQR] = useState(null);
  const [scene, setScene] = useState(null);
  const [texture, updateTexture] = useState(shoeConfig[modelId].editableZone.map(e => null));
  const [selected, updateSelected] = useState(null);
  const [colors, updateColors] = useState(shoeConfig[modelId].initialColor);

  console.log(modelId)
  const setTexture = async (newTetxure, idx) => {
    updateTexture(texture.map((e, i) => {
      if (i == idx) {
        return newTetxure;
      }

      return e;
    }));
  }

  function SmallShoe(props) {
    const group = useRef()
    const { nodes, materials } = useGLTF('/papuc_mic.glb')

    const fataTexture = useLoader(THREE.TextureLoader, texture[0] ? texture[0] : blue);
    const spateTexture = useLoader(THREE.TextureLoader, texture[1] ? texture[1] : blue);

    return (
      <group
        position={[0, -1.2, 0]}
        ref={group}
        {...props}
        dispose={null}
      >
        <group rotation={[Math.PI / 2, 0, -Math.PI / 2]} scale={[CONFIG.smallShoeScale, CONFIG.smallShoeScale, CONFIG.smallShoeScale]}>
          <mesh
            geometry={nodes.TRY3_1.geometry}
            material={materials.exterior}
            material-color={colors.exterior}
          />
          <mesh
            geometry={nodes.TRY3_2.geometry}
            material={materials.fata_edit}
            // material-color={colors.fata_edit}
            material-map={fataTexture}
          />
          <mesh
            geometry={nodes.TRY3_3.geometry}
            material={materials.spate_edit}
            // material-color={colors.spate_edit}
            material-map={spateTexture}
          />
          <mesh
            geometry={nodes.TRY3_4.geometry}
            material={materials.talpa}
            material-color={colors.talpa}
          />
          <mesh
            geometry={nodes.TRY3_5.geometry}
            material={materials.arici_exterior}
            material-color={colors.arici_exterior}
          />
          <mesh
            geometry={nodes.TRY3_6.geometry}
            material={materials.arici_interior}
            material-color={colors.arici_exterior}
          />
          <mesh
            geometry={nodes.TRY3_7.geometry}
            material={materials.hartie}
            material-color="#dedede"
          />
          <mesh
            geometry={nodes.TRY3_8.geometry}
            material={materials.fata_interior}
            material-color={colors.fata_edit}
          />
          <mesh
            geometry={nodes.TRY3_9.geometry}
            material={materials.gaura_margine}
            material-color={colors.exterior}
          />
        </group>
      </group>
    )
  }

  useGLTF.preload('/papuc_mic.glb')


  function Shoe(props) {
    const group = useRef()
    const [focused, updateFocused] = useState(null);

    const { nodes, materials } = useGLTF('/papuc_intreg.glb')
    const testTexture = useLoader(THREE.TextureLoader, img1);

    const exteriorTexture = useLoader(THREE.TextureLoader, texture[0] ? texture[0] : img0);

    const exteriorTextures = [
      useLoader(THREE.TextureLoader, red),
      useLoader(THREE.TextureLoader, yellow),
      useLoader(THREE.TextureLoader, blue),
      useLoader(THREE.TextureLoader, white),
      useLoader(THREE.TextureLoader, black)
    ];

    return (
      <group
        position={[0, -2, 0]}
        ref={group}
        {...props}
        dispose={null}
        onPointerUp={(e) => { e.stopPropagation(); if (focused == e.object.material.name) updateSelected(e.object.material.name); else updateFocused(null); }}
        onPointerDown={(e) => (e.stopPropagation(), updateFocused(e.object.material.name))}
        onPointerMove={(e) => { if (focused) updateFocused(null); }}
      // scale={[1/80, 1/80, 1/80]}
      >
        <group
          rotation={[Math.PI / 2, 0, 0]}

        >
          <mesh
            geometry={nodes.papuc_1.geometry}
            material={materials.talpa}
            material-color={colors.talpa}
          />
          <mesh
            geometry={nodes.papuc_2.geometry}
            material={materials.toc}
            material-color={colors.toc}
          />
          <mesh
            geometry={nodes.papuc_3.geometry}
            material={materials.interior}
            material-color={colors.interior}

          // material-roughness={0.1}
          // material-metalness={0.5}
          />
          <mesh
            geometry={nodes.papuc_4.geometry}
            material={materials.exterior}
            material-map={exteriorTexture}
            // material-metalness={0.2}
            material-roughness={0.6}
          />
        </group>
      </group>
    )
  }

  useGLTF.preload('/papuc_intreg.glb')


  const updateColorZone = (color, filed) => {
    console.log(color, filed)
    var obj = {};
    obj[filed] = color;
    updateColors({ ...colors, ...obj })
  }


  function CustomReflector(props) {
    const roughnessMap = useLoader(THREE.TextureLoader, roughness)
    return (
      <Reflector
        resolution={512}
        mirror={0.75}
        mixBlur={0.85}
        // mixBlur={8}
        mixStrength={1}
        position={[0, -1, 0]}
        rotation={[-Math.PI / 2, 0, Math.PI / 2]}
        minDepthThreshold={0.1}
        maxDepthThreshold={0.2}
        // depthScale={0.2}
        depthToBlurRatioBias={0.8}
        debug={0}
        args={[50, 50]}
      // distortion={distortion || 0}
      // distortionMap={distortionMap}
      >
        {(Material, props) => (
          <Material
            color="#ddd"
            metalness={0}
            // roughnessMap={roughnessMap}
            roughness={0.5}
            // normalMap={normal}
            // normalScale={_normalScale}
            {...props}
          />
        )}
      </Reflector>
    )
  }

  const goToSelect = () => {
    updateGoBack(true);
   
  }

  return (
    <div className="main-container">
      {
        goBack && (
          <div className="content-box-go-back">
            <div>
              <div className="atention-title">Esti sigur ca vrei sa schimbi modelul?</div>
              <div className="atention-details">Atentie, la pareasirea acestui model, toate modificarile facute se vor pierde!</div>
              <div className="response-box-back">

                <div onClick={() => window.location = "/"} className="confirm-back">Da</div>
                <div onClick={() => updateGoBack(false)} className="cancel-back">Renunta</div>
              </div>
            </div>
          </div>
        )
      }
      <div onClick={goToSelect} className="back-button">Alege alt model</div>
      <div className="shoe-container">
        <Canvas
          shadowMap
          concurrent
          style={{
            // width: '100vw',
            width: window.innerWidth < 1000 ? '100vw' : '60vw',
            height: Math.min(window.innerWidth, CONFIG.canvasHeight) + 'vh'
          }}
          // style={{ width: '700px', height: '700px' }}
          // style={{ width: window.innerWidth, height: window.innerWidth }}
          camera={{ position: [15, 8, 8], fov: 60 }}

          onCreated={(obj) => {
            setScene(obj.scene);
          }}
        >
          <ambientLight intensity={0.2} />
          <spotLight
            penumbra={1}
            angle={1}
            castShadow
            position={[50, 50, 0]}
            intensity={0.4}
            shadow-mapSize-width={256}
            shadow-mapSize-height={256}
          />
          <spotLight
            penumbra={1}
            angle={-1}
            castShadow
            position={[-50, 50, 0]}
            intensity={0.4}
            shadow-mapSize-width={256}
            shadow-mapSize-height={256}
          />
          <spotLight
            penumbra={1}
            angle={-1}
            castShadow
            position={[0, 50, 50]}
            intensity={0.4}
            shadow-mapSize-width={256}
            shadow-mapSize-height={256}
          />
          <spotLight
            penumbra={1}
            angle={-1}
            castShadow
            position={[0, 50, -50]}
            intensity={0.4}
            shadow-mapSize-width={256}
            shadow-mapSize-height={256}
          />
          {/* <Sky azimuth={0.5} turbidity={10} rayleigh={0.5} inclination={0.6} distance={100000} />
            <Sky azimuth={0.5} mieDirectionalG={150} turbidity={100} rayleigh={0.7} inclination={0.6} distance={1000000} /> */}

          {/* <ambientLight intensity={0.8} /> */}
          {/* <ambientLight intensity={0.6} />
        <spotLight intensity={0.3} angle={0.3} penumbra={1} position={[5, 100, 20]} /> */}
          <Suspense fallback={null}>
            {
              modelId == "shoe" &&
              <Shoe />
            }
            {
              modelId == "smallShoe" &&
              <SmallShoe />
            }
            <mesh
              rotation-x={-Math.PI / 2}
              position={[0, 0.01, 0]}
              scale={[200, 200, 200]}
              receiveShadow
              renderOrder={100000}>
              <planeBufferGeometry attach="geometry" />
              <shadowMaterial attach="material" transparent color="#251005" opacity={0.9} />
            </mesh>
            {/* <Reflector
                position={[0, -2.5, 0]}
                resolution={512}
                mirror={0}
                blur={[500, 100]}
                // blur={1}
                mixBlur={1}
                mixStrength={0.9}
                depthScale={0.5}
                minDepthThreshold={0.8}
                maxDepthThreshold={1}
                rotation-x={-Math.PI / 2}
                args={[100, 100]}>
                {(Material, props) => <Material {...props} color="" metalness={0.2} roughness={0} />}
              </Reflector> */}
            {/* <Environment preset="night" /> */}
            <ContactShadows rotation-x={Math.PI / 2} position={[0, -2, 0]} opacity={0.45} width={30} height={30} blur={0.9} far={5} />
            {/* <Environment preset="apartment" /> */}

            {/* <CustomReflector /> */}

          </Suspense>
          <OrbitControls enableZoom={false} minPolarAngle={Math.PI / 2 - 0.6} maxPolarAngle={Math.PI / 2} enablePan={false} />
          {/* <axesHelper scale={[5, 5, 5]} /> */}

        </Canvas>
      </div>
      <div className="stepper-container">
        <Accordion
          updateColorZone={updateColorZone}
          colors={colors}
          baseColor={shoeConfig[modelId].editableZone.map(e => colors[e])}
          setTexture={setTexture}
          display={selected == "exterior"}
          scene={scene}
          nrZone={shoeConfig[modelId].editableZone.length}
        />
      </div>
    </div>
  )
}
