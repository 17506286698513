import React, { useRef, useState, useEffect, Suspense } from 'react'
import { Stage, Layer, Group, Image as KonvaImage, TextPath, Text, Rect, Circle, Transformer } from "react-konva";
import CONFIG from '../config'
import setStateCallback from './setStateCallback'
import { Container, Row, Col, Button, Label, Input, Table } from 'reactstrap';
import shoeConfig from '../shoeConfig'
import { useParams } from "react-router";
const blobToBase64 = require('blob-to-base64')

class TransformerComponent extends React.Component {

    componentDidMount() {
        this.checkNode();
    }

    componentDidUpdate() {
        this.checkNode();
    }

    checkNode() {
        const stage = this.transformer.getStage();
        const { selectedShapeName } = this.props;

        var selectedNode = stage.findOne("." + selectedShapeName);

        if (selectedNode === this.transformer.node()) {
            return;
        }

        if (selectedNode) {
            const type = selectedNode.getType();
            if (type != "Group") {
                selectedNode = selectedNode.findAncestor("Group");
            }

            this.transformer.attachTo(selectedNode);
        } else {
            this.transformer.detach();
        }

        this.transformer.getLayer().batchDraw();
    }

    render() {
        return (
            <Transformer
                ref={node => {
                    this.transformer = node;
                }}
                transformstart={this.onTransformStart}
                transform={this.onTransform}
                transformend={this.onTransformEnd}
                borderStroke="grey"
                anchorStroke="grey"
                anchorCornerRadius={50}
                enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
            />
        );
    }
}

function TextureBuilder(props) {
    const { modelId } = useParams();

    const [baseColor, updateBaseColor] = useState("#ffffff");
    const [uploadedImages, updateUploadedImages] = useState(JSON.parse(localStorage.getItem('uploadedImages' + modelId + props.zone)) || []);
    const [selectedShapeName, updateSelected] = useState("")
    const [draggableItem, updateDraggableItem] = useState(false);
    const [text, updateText] = useState("");

    const stageRef = useRef(null);
    const inputRef = useRef(null);
    const inputRefPattern = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            props.setTexture(getImage(), props.idx);
        }, 600);

    }, [])

    useEffect(async () => {
        setTimeout(() => {
            props.setTexture(getImage(), props.idx);
        }, 300);

    }, [baseColor])

    useEffect(async () => {
        console.log(uploadedImages)
        setTimeout(() => {
            props.setTexture(getImage(), props.idx);
        }, 300);
        localStorage.setItem('uploadedImages' + modelId + props.zone, JSON.stringify(uploadedImages));
    }, [uploadedImages])

    useEffect(() => {
        updateBaseColor(props.baseColor);
    }, [props.baseColor])

    useEffect(() => {
        if (uploadedImages.filter(img => selectedShapeName.slice(3) == img?.id)[0]?.locked) {
            updateSelected("");
        }

    }, [uploadedImages])

    const handleImageUpload = (newImage) => {
        if (newImage.isPattern) {
            if (uploadedImages.filter(e => e.isPattern).length == 1) {
                updateUploadedImages(uploadedImages.map((e) => {
                    return e.isPattern ? newImage : e;
                }));
            }
            else {
                updateUploadedImages([newImage, ...uploadedImages]);
            }
        }
        else {
            updateUploadedImages([...uploadedImages, newImage]);
        }
    }


    const handleUpdateLastState = (attrs, idx) => {
        if (idx == -1) {
            return;
        }
        else {
            updateUploadedImages(uploadedImages.map((img, i) => {
                if (i == idx) {
                    img.state = attrs;
                }

                return img;
            }));
        }
    }

    function ColoredRect(props) {
        return (
            <Rect
                width={props.width}
                height={props.height}
                fill={props.color}
            />
        );
    }

    function TextureImage(props) {
        const [image, updateImage] = useState(null)

        useEffect(() => {
            const image = new window.Image();
            image.src = props.img;
            image.crossOrigin = 'Anonymous';
            image.onload = () => {
                updateImage(image);
            };
        }, [])

        return <KonvaImage {...props} image={image} />;
    }

    const uploadImageBlob = (event) => {

        var res = {};
        var blob = URL.createObjectURL(event.target.files[0]);
        var img = document.createElement('img');

        img.src = blob;
        img.onload = function () {
            var scaleWidth = CONFIG.builderWidth / img.width;
            var scaleHeight = CONFIG.builderHeight / img.height;
            var minScale = Math.min(scaleWidth, scaleHeight) - CONFIG.imageScaleFactor;

            res.data = blob;
            res.isPattern = false;
            res.isText = false;
            res.locked = false;
            res.name = event.target.files[0].name;
            res.id = new Date().getTime();
            res.width = img.width * minScale;
            res.height = img.height * minScale;
            res.x = (CONFIG.builderWidth - res.width) / 2;
            res.y = (CONFIG.builderHeight - res.height) / 2;
            res.state = {};

            handleImageUpload(res);
            event.target.value = null;
        }
    }

    const uploadImage = (event) => {
        var res = {};
        let document = "";
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
            document = reader.result;

            var img = new Image();
            img.onload = function () {
                var scaleWidth = CONFIG.builderWidth / img.width;
                var scaleHeight = CONFIG.builderHeight / img.height;
                var minScale = Math.min(scaleWidth, scaleHeight) - CONFIG.imageScaleFactor;

                res.data = document;
                res.isPattern = false;
                res.isText = false;
                res.locked = false;
                res.name = event.target.files[0].name;
                res.id = new Date().getTime();
                res.width = img.width * minScale;
                res.height = img.height * minScale;
                res.x = (CONFIG.builderWidth - res.width) / 2;
                res.y = (CONFIG.builderHeight - res.height) / 2;
                res.state = {};

                handleImageUpload(res);
                event.target.value = null;
            };

            img.src = document;
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    const uploadPattern = (event) => {

        var res = {};
        var blob = URL.createObjectURL(event.target.files[0]);
        var img = document.createElement('img');

        img.src = blob;
        img.onload = function () {
            var scaleWidth = CONFIG.builderWidth / img.width;
            var scaleHeight = CONFIG.builderHeight / img.height;
            var minScale = Math.max(scaleWidth, scaleHeight);

            res.data = blob;
            res.isPattern = true;
            res.isText = false;
            res.locked = true;
            res.name = event.target.files[0].name;
            res.id = new Date().getTime();
            res.width = img.width * minScale;
            res.height = img.height * minScale;
            res.x = (CONFIG.builderWidth - res.width) / 2;
            res.y = (CONFIG.builderHeight - res.height) / 2;
            res.state = {};

            handleImageUpload(res);
            event.target.value = null;
        }
    }

    const uploadText = () => {
        if (text.length == 0) {
            return;
        }

        var res = {
            data: text,
            isPattern: false,
            isText: true,
            locked: false,
            name: text,
            id: new Date().getTime(),
            x: CONFIG.builderWidth / 2,
            y: CONFIG.builderHeight / 2,
            state: {},
            color: '#000000'
        };

        handleImageUpload(res);
        updateText("");
        console.log(res)
    }

    const handleStageMouseDown = (e) => {
        if (e.target === e.target.getStage() || e.target.name() == "groupbaseimg" || uploadedImages === null) {
            updateSelected("")
            return;
        }

        const clickedOnTransformer = e.target.getParent().className === "Transformer";

        if (clickedOnTransformer) {
            return;
        }

        const name = e.target.name();

        // locked image
        if (uploadedImages.filter(img => e.target?.name().slice(3) == img.id)[0]?.locked) {
            updateSelected("")
            return;
        }

        if (name) {
            updateSelected(name)
        }
        else {
            updateSelected("")
        }
    };

    const getImage = () => {
        if (stageRef.current) {
            let len = stageRef.current.children[1]?.children.length;
            stageRef.current.children[1]?.children[len - 1]?.visible(false);
            stageRef.current.children[2]?.children[0]?.visible(false);
            console.log(stageRef.current.children[2]?.children[0])
            const uri = stageRef.current.toDataURL();
            stageRef.current.children[1]?.children[len - 1]?.visible(true);
            stageRef.current.children[2]?.children[0]?.visible(true);

            return uri;
        }
    };

    const moveUp = (idx) => {
        if (idx == uploadedImages.length - 1) {
            return;
        }

        updateUploadedImages(uploadedImages.map((e, i) => {
            if (i == idx) {
                return { ...uploadedImages[idx + 1] };
            }
            else if (i == idx + 1) {
                return { ...uploadedImages[idx] };
            }
            else {
                return e;
            }
        }))
    }

    const moveDown = (idx) => {
        console.log("Up", idx)
        if (idx == 0) {
            return;
        }

        updateUploadedImages(uploadedImages.map((e, i) => {
            if (i == idx) {
                return { ...uploadedImages[idx - 1] };
            }
            else if (i == idx - 1) {
                return { ...uploadedImages[idx] };
            }
            else {
                return e;
            }
        }))
    }

    const changeLock = (idx) => {
        updateUploadedImages(uploadedImages.map((e, i) => {
            if (i == idx) {
                e.locked = !e.locked;
            }

            return e;
        }))
    }

    return (
        <div style={{ "display": props.display ? "" : "none" }}>
            <Row>
                <Label for="txtInput">Adaugă Text</Label>
            </Row>
            <Row className="text-input">
                <Col sm={9} xs={12} className="no-padding">
                    <Input
                        className="input-box input-text"
                        name="txtInput"
                        id="txtInput"
                        placeholder="Scrie Textul Dorit"
                        value={text}
                        onChange={(e) => updateText(e.target.value)}
                    />
                </Col>
                <Col sm={3} xs={12} className="no-padding">
                    <Button className="theme-button button-txt" onClick={() => uploadText()}>
                        Adaugă
                    </Button>
                </Col>
            </Row>
            {uploadedImages.slice().reverse().map((e, idx) => {
                let len = uploadedImages.length - 1;
                return (
                    <Row>
                        <Col sm={6} xs={12} className="no-padding">
                            {
                                e.isText &&
                                <input
                                    className="color-input"
                                    type="color"
                                    value={e.color}
                                    onChange={(e) => updateUploadedImages(uploadedImages.map((txt, i) => {
                                        if (i == len - idx) {
                                            txt.color = e.target.value;
                                        }

                                        return txt;
                                    }))}
                                />
                            }
                            {e.name.slice(0, CONFIG.maxNameChar)}
                        </Col>
                        <Col sm={6} xs={12} className="no-padding control-button-container">
                            <div><button className="theme-button" disabled={idx == 0} onClick={() => moveUp(len - idx)}>Up</button></div>
                            <div><button className="theme-button" disabled={idx == len} onClick={() => moveDown(len - idx)}>Down</button></div>
                            <div><button className="theme-button" onClick={() => changeLock(len - idx)}>{e.locked ? "UNLOCK" : "LOCK"}</button></div>
                            <div><button className="theme-button" onClick={() => updateUploadedImages(uploadedImages.filter((e, i) => i != len - idx))} >X</button></div>
                        </Col>
                    </Row>
                )
            })}
            <Row className="centered-container">
                {/* <div>{JSON.stringify(selectedShapeName)}</div> */}
                {
                    <Stage
                        ref={stageRef}
                        width={CONFIG.builderWidth}
                        height={CONFIG.builderHeight}
                        onMouseDown={handleStageMouseDown}
                        className={draggableItem ? "draggable-item" : "not-draggable-item"}
                    >
                        <Layer key="baseColor">
                            <ColoredRect
                                color={baseColor}
                                width={CONFIG.builderWidth}
                                height={CONFIG.builderHeight}
                            />
                        </Layer>
                        {
                            uploadedImages &&
                            <Layer key={"imgcontainer"}>
                                {uploadedImages.map((elem, idx) => {
                                    return elem.isText
                                        ?
                                        (
                                            <>
                                                <Group
                                                    {...(elem?.state)}
                                                    key={"goruptxt" + elem?.id}
                                                    name={"goruptxt" + elem?.id}
                                                    draggable={!elem?.locked}
                                                    onDragEnd={(e) => handleUpdateLastState(e.target.attrs, idx)}
                                                    onTransformEnd={(e) => handleUpdateLastState(e.target.attrs, idx)}
                                                    onMouseEnter={() => !elem?.locked && updateDraggableItem(true)}
                                                    onMouseLeave={() => updateDraggableItem(false)}
                                                >
                                                    <Text
                                                        key={"txt" + elem?.id}
                                                        name={"txt" + elem?.id}
                                                        text={elem?.data}
                                                        fontSize={40}
                                                        align="center"
                                                        fill={elem?.color}
                                                        x={elem?.x}
                                                        y={elem?.y}
                                                    />
                                                </Group>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Group
                                                    {...(elem?.state)}
                                                    key={"gorupimg" + elem?.id}
                                                    name={"gorupimg" + elem?.id}
                                                    draggable={!elem?.locked}
                                                    onDragEnd={(e) => handleUpdateLastState(e.target.attrs, idx)}
                                                    onTransformEnd={(e) => handleUpdateLastState(e.target.attrs, idx)}
                                                    className={!elem?.locked ? "draggable-item" : "not-draggable-item"}
                                                    onMouseEnter={() => !elem?.locked && updateDraggableItem(true)}
                                                    onMouseLeave={() => updateDraggableItem(false)}
                                                >
                                                    <TextureImage
                                                        key={"img" + elem?.id}
                                                        name={"img" + elem?.id}
                                                        img={elem?.data}
                                                        width={elem?.width}
                                                        height={elem?.height}
                                                        x={elem?.x}
                                                        y={elem?.y}
                                                    />
                                                </Group>
                                            </>
                                        )
                                })}
                                <TransformerComponent
                                    key={"transformer"}
                                    selectedShapeName={selectedShapeName}
                                />
                            </Layer>
                        }
                        <Layer key="UV-margin" listening={false}>
                            {/* Aici e conturul */}
                            <TextureImage
                                width={CONFIG.builderWidth}
                                height={CONFIG.builderHeight}
                                img={shoeConfig[modelId].margin[props.idx]}
                            />
                        </Layer>
                    </Stage>
                }
                <input ref={inputRef} hidden id="upload-file" type="file" onChange={uploadImage}></input>
                <input ref={inputRefPattern} hidden id="upload-file" type="file" onChange={uploadPattern}></input>
                <button className="theme-button upload-button" onClick={() => inputRef.current.click()}>Upload</button>
                <button className="theme-button upload-button" onClick={() => inputRefPattern.current.click()}>Upload Pattern</button>
            </Row>
        </ div>
    )
}

export default TextureBuilder;