export default {
    shoe: {
        zoneName: ["talpa", "toc", "exterior", "interior"],
        editableZone: ["exterior"],
        zoneAlias: {
            talpa: "Talpă",
            toc: "Toc",
            exterior: "Exterior",
            interior: "Interior"
        },
        zoneColor: {
            talpa: ["#ffffff", "#e74c3c", "#17202a"],
            toc: ["#ffffff", "#17202a"],
            exterior: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"],
            interior: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"]
        },
        initialColor: {
            talpa: "#17202a",
            toc: "#17202a",
            exterior: "#5dade2",
            interior: "#17202a"
        },
        margin: ["/shoe_margin_flipped.png"]
    },
    smallShoe: {
        zoneName: ["exterior", "fata_edit", "spate_edit", "talpa", "arici_exterior"],
        editableZone: ["fata_edit", "spate_edit"],
        zoneAlias: {
            exterior: "Exterior",
            fata_edit: "Față Edit",
            spate_edit: "Spate Edit",
            talpa: "Talpă",
            arici_exterior: "Arici"
        },
        zoneColor: {
            exterior: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"],
            fata_edit: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"],
            spate_edit: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"],
            talpa: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"],
            arici_exterior: ["#e74c3c", "#f4d03f", "#5dade2", "#ffffff", "#17202a"]
        },
        initialColor: {
            exterior: "#f4d03f",
            fata_edit: "#5dade2",
            spate_edit: "#5dade2",
            talpa: "#17202a",
            arici_exterior: "#5dade2"
        },
        margin: ["/panda_fata.png", "/panda_spate.png"]
    }
}