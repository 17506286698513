import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter'
import axios from 'axios';

function exportGLTF(scene, updateQRURL) {
    console.log(scene)
    console.log(scene.children)
    scene.children[4].scale.x *= 1 / 80;
    scene.children[4].scale.y *= 1 / 80;
    scene.children[4].scale.z *= 1 / 80;
    const gltfExporter = new GLTFExporter();

    const options = {
        binary: false
    };

    gltfExporter.parse(scene.children[4], function(result) {

        if (result instanceof ArrayBuffer) {
            // saveArrayBuffer(result, 'scene.glb');
            saveArrayBuffer(result, 'scene.glb');
        } else {
            console.log(result);
            const output = JSON.stringify(result, null, 2);
            // saveString(output, 'scene.gltf');
            sendString(output, updateQRURL);
            scene.children[4].scale.x *= 80;
            scene.children[4].scale.y *= 80;
            scene.children[4].scale.z *= 80;
        }

    }, options);
}

function sendString(text, setURL) {
    const file = new Blob([text], { type: 'text/plain' });

    axios.post("https://api.viewobject.holardev.ro/viewAR", {
            arFile: text
        })
        .then((res) => {
            console.log(res.data)
            setURL(res.data.message);
            // window.location.href = res.data.message;
        })
}

function saveString(text, filename) {
    save(new Blob([text], { type: 'text/plain' }), filename);
}

function saveArrayBuffer(buffer, filename) {
    save(new Blob([buffer], { type: 'application/octet-stream' }), filename);
}

function save(blob, filename) {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
}

export default exportGLTF;