export default {
    margins: ["/margin_circle.png", "/margin_square.png", "/margin_triangle.png"],
    margin: "/shoe_margin_flipped.png",
    canvasHeight: 90,
    canvasWidth: 60,
    builderHeight: 300,
    builderWidth: 300,
    imageScaleFactor: 0.1,
    colors: [
        "#e74c3c",
        "#f4d03f",
        "#5dade2",
        "#ffffff",
        "#17202a",
    ],
    portionsAlias: ["fata", "dreapta", "stanga"],
    entireZoneName: ["talpa", "toc", "exterior", "interior"],
    entireZoneAlias: {
        talpa: "Talpă",
        toc: "Toc",
        exterior: "Exterior",
        interior: "Interior"
    },
    portionsNr: 3,
    maxStep: 5,
    shoeStep: 2,
    maxNameChar: 10,
    
    smallShoeScale: 10
}