import React, { useRef, useState, useEffect, Suspense } from 'react'
import { useGLTF, Html, OrbitControls, ContactShadows, Reflector, useTexture, Sky, Environment, Text, Billboard } from '@react-three/drei'
import { Canvas, useLoader, useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import img0 from '../textures/rainbow.png'
import img1 from '../textures/fill.png'
import CONFIG from '../config'
import './style.css'
import TextureBuilder from './TextureBuilder.js'
import Exporter from './Exporter'
import QRCode from 'qrcode.react';


import red from '../textures/red.png'
import yellow from '../textures/yellow.png'
import blue from '../textures/blue.png'
import white from '../textures/white.png'
import black from '../textures/black.png'
import roughness from '../textures/roughness_floor.jpeg';

export default function ShoeScene(props) {
  const [qr, updateQR] = useState(null);
  const [scene, setScene] = useState(null);
  const [frontTexture, updateFrontTexture] = useState(null);
  const [rightTexture, updateRightTexture] = useState(null);
  const [leftTexture, updateLeftTexture] = useState(null);
  // const [textures, updateTextures] = useState([null, null, null]);
  const [uploadedImage, updateUploadedImage] = useState([null, null, null]);
  const [lastState, updateLastState] = useState([{}, {}, {}])
  const [selected, updateSelected] = useState(null);
  const [colors, updateColors] = useState({
    talpa: "#000000",
    toc: "#000000",
    exterior: "#5dade2",
    interior: "#000000"
  });

  const handleImageUpload = (index, newImage) => {
    updateUploadedImage(uploadedImage.map((e, i) => {
      if (i == index) {
        return newImage;
      }
      else {
        return e;
      }
    }));
  }

  const handleUpdateLastState = (index, newState, newTetxure) => {
    updateLastState(lastState.map((elem, i) => {
      return (index == i ? newState : elem);
    }));

    setTexture(index, newTetxure);
    // updateTextures(textures.map((elem, i) => {
    //   return (index == i ? newTetxure : elem);
    // }))
  }

  const handleUpdateColor = (index, newTetxure) => {
    setTexture(index, newTetxure);
    // updateTextures(textures.map((elem, i) => {
    //   return (index == i ? newTetxure : elem);
    // }))
  }

  const setTexture = async (index, newTetxure) => {
    // console.log("update", index, newTetxure)
    // updateTextures(textures.map((elem, i) => {
    //   return (index == i ? newTetxure : elem);
    // }))

    if (index == 0) {
      updateFrontTexture(newTetxure);
    }
    else if (index == 1) {
      updateRightTexture(newTetxure);
    }
    else if (index == 2) {
      updateLeftTexture(newTetxure);
    }
  }

  function Label(props) {
    return (
      <Billboard
        prepend={true}
        // transform={true}
        // sprite={true}
        position={props.position}
        distanceFactor={20}
        zIndexRange={[100, 0]}
      >
        <div className="label">
          <div>{props.labelName}</div>
        </div>
      </Billboard>
    )
  }

  function Shoe(props) {
    const group = useRef()
    const [focused, updateFocused] = useState(null);




    const { nodes, materials } = useGLTF('/papuc.glb')
    const testTexture = useLoader(THREE.TextureLoader, img1);

    const portionTextures = [
      useLoader(THREE.TextureLoader, frontTexture ? frontTexture : img0),
      useLoader(THREE.TextureLoader, rightTexture ? rightTexture : img0),
      useLoader(THREE.TextureLoader, leftTexture ? leftTexture : img0)
    ];

    const exteriorTextures = [
      useLoader(THREE.TextureLoader, red),
      useLoader(THREE.TextureLoader, yellow),
      useLoader(THREE.TextureLoader, blue),
      useLoader(THREE.TextureLoader, white),
      useLoader(THREE.TextureLoader, black)
    ];


    return (
      <group

        ref={group}
        {...props}
        dispose={null}
        onPointerUp={(e) => { e.stopPropagation(); if (focused == e.object.material.name) updateSelected(e.object.material.name); else updateFocused(null); }}
        onPointerDown={(e) => (e.stopPropagation(), updateFocused(e.object.material.name))}
        onPointerMove={(e) => { if (focused) updateFocused(null); }}
      // scale={[1/80, 1/80, 1/80]}
      >
        <group
          rotation={[Math.PI / 2, 0, 0]}
        >
          <mesh geometry={nodes.papuc_1.geometry} material={materials.talpa} material-color={colors.talpa} />
          <mesh geometry={nodes.papuc_2.geometry} material={materials.toc} material-color={colors.toc} />
          {/* <mesh geometry={nodes.papuc_3.geometry} material={materials.exterior} material-color={colors.exterior} /> */}
          <mesh geometry={nodes.papuc_3.geometry} material={materials.exterior} material-map={exteriorTextures[CONFIG.colors.indexOf(colors.exterior)]} />
          <mesh geometry={nodes.papuc_4.geometry} material={materials.interior} material-color={colors.interior} />
          <mesh geometry={nodes.papuc_5.geometry} material={materials.fata} material-map={frontTexture ? portionTextures[CONFIG.portionsAlias.indexOf("fata")] : exteriorTextures[CONFIG.colors.indexOf(colors.exterior)]} />
          <mesh geometry={nodes.papuc_6.geometry} material={materials.dreapta} material-map={rightTexture ? portionTextures[CONFIG.portionsAlias.indexOf("dreapta")] : exteriorTextures[CONFIG.colors.indexOf(colors.exterior)]} />
          <mesh geometry={nodes.papuc_7.geometry} material={materials.stanga} material-map={leftTexture ? portionTextures[CONFIG.portionsAlias.indexOf("stanga")] : exteriorTextures[CONFIG.colors.indexOf(colors.exterior)]} />
        </group>
      </group>
    )
  }



  const v1 = new THREE.Vector3()
  const ref = React.useRef < THREE.Object3D > (null)
  const ref1 = useRef()
  const overrideCalculatePosition = (el, camera, size) => {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld)
    objectPos.project(camera)
    const widthHalf = size.width / 2
    const heightHalf = size.height / 2
    return [
      Math.min(size.width - 100, Math.max(0, objectPos.x * widthHalf + widthHalf)),
      Math.min(size.height - 20, Math.max(0, -(objectPos.y * heightHalf) + heightHalf)),
    ]
  }

  // const normal = useTexture("./roughness_floor.jpeg")
  // const roughness2 = useTexture(roughness)
  return (
    <div className="main-container">
      <Canvas
        shadows


        style={{ width: '700px', height: '700px' }}
        camera={{ position: [20, 15, 15], fov: 60 }}
        onCreated={(obj) => {
          setScene(obj.scene);
        }}
      >
        <ambientLight intensity={0.6} />
        {/* <color attach="background" args={['#dddddd']} /> */}
        {/* <fog attach="fog" args={['#202020', 20, 25]} /> */}
        <directionalLight position={[-10, 0, -15]} intensity={0.1} />
        <directionalLight position={[10, 10, 10]} intensity={0.1} />
        <directionalLight
          castShadow
          position={[0.5, 8, 5]}
          intensity={0.4}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-far={30}
          shadow-camera-left={10}
          shadow-camera-right={10}
          shadow-camera-top={0}
          shadow-camera-bottom={-10}
        />
        {/* <Sky azimuth={0.5} turbidity={10} rayleigh={0.5} inclination={0.6} distance={100000} /> */}
        {/*  */}
        <Sky azimuth={0.5} mieDirectionalG={150} turbidity={100} rayleigh={0.7} inclination={0.6} distance={1000000} />

        {/* <ambientLight intensity={0.8} /> */}
        {/* <ambientLight intensity={0.6} />
        <spotLight intensity={0.3} angle={0.3} penumbra={1} position={[5, 100, 20]} /> */}
        <Suspense fallback={null}>

          {/* <Text
            ref={ref1}
          >
            La Bau
          </Text>

          <Html
            prepend
            occluder={ref}
            // calculatePosition={overrideCalculatePosition}
            center
            // fullscreen
            // distanceFactor={20}
            // zIndexRange={[100, -100]}
            transform
            // sprite
            position={[0, 0, -10]}
            zIndexRange={[0,5]}
          // distanceFactor={20}
          // zIndexRange={[500, 0]}
          >
            <div className="label">
              HELLO
            </div>
          </Html> */}

          {/* <Label labelName="Fata" position={[0, 3, 8]} />
          <Label labelName="Stanga" position={[2, 4.5, -8]} />
          <Label labelName="Dreapta" position={[-3.6, 4.5, -8]} />
          <Label labelName="Exterior" position={[-3.6, 3.2, 7]} />
          <Label labelName="Interior" position={[0, 2, 0]} />
          <Label labelName="Toc" position={[2.7, 1.5, -7.5]} />
          <Label labelName="Talpa" position={[2, 1.5, -4.5]} /> */}
          {/* <ContactShadows rotation-x={Math.PI / 2} position={[0, -10, 0]} opacity={0.25} width={10} height={10} blur={2} far={1} /> */}
          

          <Shoe />
          {/* <Environment preset="night" /> */}
          <ContactShadows rotation-x={Math.PI / 2} position={[0, -1, 0]} opacity={1} width={300} height={300} blur={0.5} far={2} />



        </Suspense>
        <OrbitControls minPolarAngle={Math.PI / 2 - 0.6} maxPolarAngle={Math.PI / 2} />
        {/* <axesHelper scale={[5, 5, 5]} /> */}

      </Canvas>
      <div>
        <div><button onClick={() => Exporter(scene, updateQR)}>Vizualizare in AR</button></div>
        {
          qr != null &&
          <div><QRCode value={qr} /></div>
        }
        {/* {selected && <div>{JSON.stringify(colors)}</div>} */}
        {/* {selected && <div>{JSON.stringify(selected)}</div>} */}
        {selected && CONFIG.colors.map((elem, i) => {
          return (
            <button
              key={i}
              className="color-button"
              style={{ "background-color": elem }}
              onClick={(e) => {
                if (selected) {
                  var copyColor = { ...colors };
                  copyColor[CONFIG.portionsAlias.indexOf(selected) == -1 ? selected : "exterior"] = elem;
                  updateColors(copyColor);
                }
              }}
            />
          )
        })}
        {/* {selected && !Object.keys(colors).includes(selected) &&
          <TextureBuilder
            baseColor={colors.exterior}
            handleImageUpload={handleImageUpload}
            uploadedImage={uploadedImage}
            handleUpdateLastState={handleUpdateLastState}
            lastState={lastState}
            selected={selected}
            handleUpdateColor={handleUpdateColor}
            setTexture={setTexture}
          />} */}
        <button style={{ "display": "none" }}>Ha</button>
        <TextureBuilder
          baseColor={colors.exterior}
          handleImageUpload={handleImageUpload}
          uploadedImage={uploadedImage}
          handleUpdateLastState={handleUpdateLastState}
          lastState={lastState}
          selected="fata"
          handleUpdateColor={handleUpdateColor}
          setTexture={setTexture}
          display={selected == "fata"}
        />
        <TextureBuilder
          baseColor={colors.exterior}
          handleImageUpload={handleImageUpload}
          uploadedImage={uploadedImage}
          handleUpdateLastState={handleUpdateLastState}
          lastState={lastState}
          selected="dreapta"
          handleUpdateColor={handleUpdateColor}
          setTexture={setTexture}
          display={selected == "dreapta"}
        />
        <TextureBuilder
          baseColor={colors.exterior}
          handleImageUpload={handleImageUpload}
          uploadedImage={uploadedImage}
          handleUpdateLastState={handleUpdateLastState}
          lastState={lastState}
          selected="stanga"
          handleUpdateColor={handleUpdateColor}
          setTexture={setTexture}
          display={selected == "stanga"}
        />
      </div>
    </div>
  )
}

useGLTF.preload('/papuc.glb')
